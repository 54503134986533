import { useQuery } from 'react-query';
import { MoneyCollection } from '../../../interfaces/moneyCollection.interface';
import { apiRQ } from '../../api';

const getMoneyCollection = async (id: string): Promise<MoneyCollection> => {
  const resp = await apiRQ.get<MoneyCollection>(`/money-collection/${id}`);

  return resp.data;
};

export const useMoneyCollection = (id: string) => {
  return useQuery<MoneyCollection, Error>(['useMoneyCollection', id], () => getMoneyCollection(id), {
    enabled: id !== 'create' && !!id,
    cacheTime: 200,
    staleTime: 200,
    refetchOnWindowFocus: false,
  });
};
