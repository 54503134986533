import { Product } from '../../../interfaces/product.interface';
import { GetEntities } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';
import { useQuery } from 'react-query';

const getProducts = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<GetEntities<Product>> => {
  const resp = await apiRQ.get<GetEntities<Product>>('/products', { params });

  return resp.data;
};

export const useProducts = (page: number, sortBy = 'id', order = 'ASC', search = '') => {
  return useQuery<GetEntities<Product>, Error>(
    ['getProducts', page, sortBy, order, search],
    () => getProducts({ page, orderBy: sortBy, order, search: search }),
    {
      cacheTime: 4000,
      staleTime: 10000,
      refetchOnWindowFocus: false,
    },
  );
};
