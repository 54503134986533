import i18next from 'i18next';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import { GetVatInfo, useVatInfo } from '../../../api/hooks/customers/useVatInfo';
import { BSelect } from '../../../components/molecules/BSelect';
import { BSelectSearch } from '../../../components/molecules/BSelectSearch';
import { CustomerType } from '../../../interfaces/customer.interface';
import { CustomerVatNumber } from './CustomerVatNumber';

interface Props {
  countryField: any;
  customerTypeField: any;
  vatField: any;
  currencyField: any;
  vatRegimeField: any;
  vatExcCatField: any;
  formik: any;
}

const getTypeSameCountry = (types: CustomerType[]): CustomerType | undefined => {
  return types?.[0];
};

const getTypeEuCountry = (types: CustomerType[]): CustomerType | undefined => {
  return types?.[1];
};

const getTypeThirdCountry = (types: CustomerType[]): CustomerType | undefined => {
  return types?.[2];
};

const getTypeToSet = (customerTypeField: any, type: CustomerType) => {
  return {
    label: i18next.t(`${customerTypeField.translationGroupKey}.${type?.name}`),
    value: type?.id,
    object: type,
  };
};

const setCustomerFieldType = (customerTypeField: any, formik: any) => {
  const country = formik?.values?.object?.countryId || formik?.values?.countryId;
  if (!country) return;

  if (country?.aadeCode === '300') {
    const type = getTypeSameCountry(customerTypeField?.data);
    if (type) {
      formik.setFieldValue(customerTypeField.name, getTypeToSet(customerTypeField, type));
    }
  } else if (country?.region !== 'EU') {
    const type = getTypeThirdCountry(customerTypeField?.data);
    if (type) {
      formik.setFieldValue(customerTypeField.name, getTypeToSet(customerTypeField, type));
    }
  } else {
    const type = getTypeEuCountry(customerTypeField?.data);
    if (type) {
      formik.setFieldValue(customerTypeField.name, getTypeToSet(customerTypeField, type));
    }
  }
};

const setDataFromAadeRetrieval = (data: GetVatInfo, formik: any) => {
  if (data?.data?.mainInfo?.afm && !data?.data?.mainInfo?.stop_date) {
    const mainInfo = data?.data?.mainInfo;
    formik.setFieldValue('companyName', mainInfo.onomasia);
    // Reset error of field
    setTimeout(() => {
      formik.setFieldError('companyName', undefined);
    });
    formik.setFieldValue('city', mainInfo.postal_area_description);
    formik.setFieldValue('postalCode', mainInfo.postal_zip_code);
    formik.setFieldValue('address', `${mainInfo.postal_address} ${mainInfo?.postal_address_no}`);

    if (data?.data?.acts?.[0]?.firm_act_descr) {
      const val = (data?.data?.acts?.[0]?.firm_act_descr || '').substring(0, 498);
      formik.setFieldValue('profession', val);
    }

    const doy = data.doy;
    if (doy) {
      formik.setFieldValue('doyId', { label: doy.name, value: doy.id, object: doy });
    }
  }
};

export const CustomerMainInfo = ({
  countryField,
  customerTypeField,
  vatField,
  currencyField,
  vatRegimeField,
  vatExcCatField,
  formik,
}: Props) => {
  const { id } = useParams<{ id: string }>();
  const customerTypeSelected = useMemo(() => formik?.values?.customerTypeId, [formik?.values?.customerTypeId]);
  const { data, isLoading } = useVatInfo(
    formik?.values?.vatNumber || '',
    customerTypeSelected?.aadeCode || customerTypeSelected?.object?.aadeCode,
    id,
  );
  const counter = useRef(0);
  counter.current++;

  useEffect(() => {
    if (counter.current > 1) {
      setCustomerFieldType(customerTypeField, formik);
    }
  }, [formik.values.countryId, id]);

  useEffect(() => {
    // Setting only on new client
    if (data?.success && id === undefined) {
      setDataFromAadeRetrieval(data, formik);
    }
  }, [data, id]);

  useEffect(() => {
    const aadeCode = formik.values?.vatRegimeId?.object?.aadeCode || formik.values?.vatRegimeId?.aadeCode;
    if (aadeCode !== '3') {
      formik.setFieldValue(vatExcCatField.name, null);
    }
  }, [formik.values?.vatRegimeId, vatExcCatField?.name]);

  const getVatExceptionSelect = useCallback(() => {
    if (formik.values?.vatRegimeId?.object?.aadeCode === '3' || formik.values?.vatRegimeId?.aadeCode === '3') {
      return <BSelect formik={formik} field={vatExcCatField} />;
    }
  }, [formik.values?.vatRegimeId, vatExcCatField]);

  return (
    <div className="docForm">
      <div className="padding-t-24 padding-b-24 padding-l-8 padding-r-8 flex-start-center-column-nowrap flex-gap-30 positionRelative">
        <div className="flex-start-center-row-wrap flex-gap-30">
          <BSelectSearch field={countryField} useHook={countryField.useHook} formik={formik} focusOnMount />
          <BSelect field={customerTypeField} formik={formik} />
          <CustomerVatNumber field={vatField} formik={formik} isLoading={isLoading} />
        </div>
        <div className="flex-start-center-row-wrap flex-gap-30">
          <BSelect field={currencyField} formik={formik} disabled={currencyField.disabled} />
          <BSelect field={vatRegimeField} formik={formik} />
          {getVatExceptionSelect()}
        </div>
      </div>
    </div>
  );
};
