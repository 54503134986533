import * as React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { Company } from '../../../interfaces/company.interface';
import { pdfStyles } from './pdfStyles';
import { useCallback, useMemo } from 'react';

interface Props {
  company: Company;
}

export const PdfCompanyTitle = ({ company }: Props) => {
  const { t } = useTranslation();
  const isBranch = useMemo(() => {
    return Number(company?.branchSerial || 0) > 0;
  }, [company?.branchSerial]);

  const getBranchAddress = useCallback(
    (prefix: string) => {
      return (
        <View style={styles.container2}>
          <Text style={styles.body}>{`${prefix}: ${company?.address} ${company?.city} ${company?.postalCode}`}</Text>
        </View>
      );
    },
    [company?.address, company?.city, company?.postalCode],
  );

  const getMasterAddress = useCallback(() => {
    if (company?.parentCompany) {
      return (
        <View style={styles.container2}>
          <Text style={styles.body}>{`${t(
            'PDF.MASTER_COMPANY_TITLE',
          )}: ${company?.parentCompany?.address} ${company?.parentCompany?.city} ${company?.parentCompany?.postalCode}`}</Text>
        </View>
      );
    }
  }, [company?.parentCompany, t]);

  const getCompanyAddress = useCallback(() => {
    if (isBranch) {
      return (
        <View>
          {getBranchAddress(t('PDF.BRANCH_COMPANY_TITLE'))}
          {getMasterAddress()}
        </View>
      );
    }

    return getBranchAddress(t('PDF.ADDRESS'));
  }, [getBranchAddress, getMasterAddress, isBranch, t]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{company?.title}</Text>
      <View style={[styles.container2, { width: 360 }]}>
        <Text style={[styles.body, pdfStyles.maxLines2]}>{company?.profession}</Text>
      </View>
      <View style={styles.container2}>
        <Text style={styles.body}>
          {t('PDF.VAT_NUMBER')}: {company?.vatNumber}
          {' - '}
        </Text>
        <Text style={styles.body}>
          {t('PDF.DOY')}: {company?.doy?.name}
        </Text>
      </View>
      {getCompanyAddress()}
      <View style={styles.container2}>
        {company?.phoneNumber && (
          <Text style={styles.body}>
            {t('PDF.PHONE_NUMBER')}: {company?.phoneNumber}
          </Text>
        )}
        {company?.email && (
          <Text style={styles.body}>
            {' - '} {t('PDF.EMAIL')}: {company?.email}
          </Text>
        )}
        {company?.websiteUrl && (
          <Text style={styles.body}>
            {' - '} {t('SETTINGS.WEBSITE')}: {company?.websiteUrl}
          </Text>
        )}
      </View>
      <View style={styles.container2}>
        {company?.gemiInfo && (
          <Text style={styles.body}>
            {t('PDF.GEMI_NUMBER')}: {company?.gemiInfo}
          </Text>
        )}
        {company?.menoNumber && (
          <Text style={styles.body}>
            {' - '} {t('PDF.MENO_NUMBER')}: {company?.menoNumber}
          </Text>
        )}
        {company?.eaeNumber && (
          <Text style={styles.body}>
            {t('PDF.EAE_NUMBER')}: {company?.eaeNumber}
          </Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: 500,
  },
  title: {
    fontFamily: 'Manrope',
    fontWeight: 'bold',
  },
  container2: {
    flexDirection: 'row',
  },
  body: {
    fontSize: 9,
  },
});
