import { useQuery } from 'react-query';
import { SORT_BY_MONEY_COLLECTION } from '../../../interfaces/enumerables';
import { MoneyCollection } from '../../../interfaces/moneyCollection.interface';
import { GetEntities } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';

const getMoneyCollectionList = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<GetEntities<MoneyCollection>> => {
  const resp = await apiRQ.get<GetEntities<MoneyCollection>>('/money-collection', { params });

  return resp.data;
};

export const useMoneyCollectionList = (
  page: number,
  sortBy = SORT_BY_MONEY_COLLECTION.CREATED_AT,
  order = 'ASC',
  search: string,
) => {
  return useQuery<GetEntities<MoneyCollection>, Error>(
    ['useMoneyCollectionList', page, sortBy, order, search],
    () => getMoneyCollectionList({ page, orderBy: sortBy, order, search: search }),
    {
      cacheTime: 200,
      staleTime: 200,
      refetchOnWindowFocus: false,
    },
  );
};
