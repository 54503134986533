import { GetEntities } from '../../../interfaces/shared.interface';
import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { Customer } from '../../../interfaces/customer.interface';

const getCustomers = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<GetEntities<Customer>> => {
  const resp = await apiRQ.get<GetEntities<Customer>>('/customers', { params });

  return resp.data;
};

export const useCustomers = (page: number, orderBy = 'id', order = 'ASC', search: string) => {
  return useQuery<GetEntities<Customer>, Error>(
    ['getCustomers', page, orderBy, order, search],
    () => getCustomers({ page, orderBy: orderBy, order, search: search }),
    {
      cacheTime: 500,
      staleTime: 500,
      refetchOnWindowFocus: false,
    },
  );
};
