import { apiRQ } from '../../api';
import { useQuery } from 'react-query';
import { Customer } from '../../../interfaces/customer.interface';

const getCustomer = async (id: string): Promise<Customer> => {
  const resp = await apiRQ.get<Customer>(`/customers/${id}`);

  return resp.data;
};

export const useCustomer = (id: string) => {
  return useQuery<Customer, Error>(['getCustomer', id], () => getCustomer(id), {
    enabled: !!id,
    cacheTime: 500,
    staleTime: 500,
    refetchOnWindowFocus: false,
  });
};
