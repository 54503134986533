import { apiRQ } from '../api';
import { useQuery } from 'react-query';
import { PaymentType } from '../../interfaces/paymentTypes.interface';
import { VatExceptionCategoryInterface } from '../../interfaces/vatExceptionCategory.interface';
import { DocumentTypeInterface } from '../../interfaces/documentType.interface';
import { CustomerVatRegime, DocPurpose, TaxWithhold } from '../../interfaces/invoice.interface';

export interface FormData {
  paymentTypes: PaymentType[];
  docTypes: DocumentTypeInterface[];
  vatExceptionCategories: VatExceptionCategoryInterface[];
  customerVatRegimes: CustomerVatRegime[];
  docPurposes: DocPurpose[];
  taxWithholds: TaxWithhold[];
}

export const getFormData = async (): Promise<FormData> => {
  const resp = await apiRQ.get<FormData>('/form-data');

  return resp.data;
};

export const useForm = () => {
  return useQuery<FormData, Error>(['useForm'], () => getFormData(), {
    cacheTime: 10000,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};
