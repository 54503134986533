import { useQuery } from 'react-query';
import { Company } from '../../interfaces/company.interface';
import { GenericResponse } from '../../interfaces/shared.interface';
import { apiRQ } from '../api';

type CompaniesResponse = Company[] & GenericResponse;

export const getCompanies = async (): Promise<CompaniesResponse> => {
  const resp = await apiRQ.get<CompaniesResponse>('/companies');

  return resp.data || [];
};

export const useCompanies = () => {
  return useQuery<Company[], Error>(['getCompanies'], getCompanies, {
    cacheTime: 1000 * 60 * 60, // 1 hour
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

export const getCompany = async (id: string): Promise<Company> => {
  const resp = await apiRQ.get<Company>(`/companies/${id}`);

  return resp.data || [];
};

export const useCompany = (id: string) => {
  return useQuery<Company, Error>(['getCompany', id], () => getCompany(id), {
    enabled: !!id,
    refetchOnWindowFocus: false,
    cacheTime: 6000,
    staleTime: 6000,
  });
};
