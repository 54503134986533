import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle } from '@ionic/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { usePlans } from '../../../api/hooks/usePlans';
import { AppPage, appPagesMenu, indexOfPlanSensitivePages, PATHS } from '../../../navigation/data';
import { selectSelectedCompany, selectSelectedCompanyName } from '../../../store/companies/companiesSlice';
import logo from '../../../theme/assets/logo.png';
import './Menu.scss';

const Menu: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const company = useSelector(selectSelectedCompany);
  const companyName = useSelector(selectSelectedCompanyName);
  const { data, isSuccess } = usePlans(company?.vatNumber || '');

  const navigateToCompanies = () => {
    history.push(PATHS.COMPANIES);
  };

  const getLogo = useCallback(() => {
    return (
      <div className="logoContainer">
        <IonImg src={logo} style={styles.logo} />
      </div>
    );
  }, []);

  const getAvailableMenuFeatures = useCallback(
    (features: AppPage[]) => {
      if (isSuccess) {
        const activePlans = data?.activePlans || {};
        for (let i = 0; i < indexOfPlanSensitivePages.length; i++) {
          const featureIndex = indexOfPlanSensitivePages[i];
          const feature = features[featureIndex];
          if (feature?.planToCheck && !activePlans[feature.planToCheck]) {
            features.splice(featureIndex, 1);
            i--;
          }
        }
      }

      return features;
    },
    [data?.activePlans, isSuccess],
  );

  const getMenuItem = useCallback(
    (index: number, appPage: AppPage) => {
      return (
        <IonMenuToggle key={index} autoHide={false}>
          <IonItem
            data-testid={`tab_${index}`}
            className={location.pathname === appPage.url ? 'selected' : ''}
            routerLink={appPage.url}
            routerDirection="none"
            lines="none"
            detail={false}>
            <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
            <IonLabel>{t(appPage.title)}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      );
    },
    [location?.pathname],
  );

  const getMenuItems = useCallback(() => {
    const pages = getAvailableMenuFeatures([...appPagesMenu]);

    return pages.map((appPage, index) => getMenuItem(index, appPage));
  }, [getAvailableMenuFeatures, getMenuItem]);

  return (
    <IonMenu contentId="main" type="overlay" style={{ width: 260 }}>
      <IonContent>
        <div className="mediumText5 margin-t-minus-10 padding-l-10 cursorPointer" onClick={navigateToCompanies}>
          {companyName}
        </div>
        <IonList id="inbox-list pad-t-0">{getMenuItems()}</IonList>
        {getLogo()}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

const styles = {
  logo: {
    width: 200,
    height: 100,
  },
};
