import { Document, Font } from '@react-pdf/renderer';
import { useCallback } from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { splitToPagesDynamic } from '../../../services/utils/pdf';
// @ts-expect-error Import
import ManropeRegular from '../../../theme/assets/fonts/Manrope-Regular.ttf';
// @ts-expect-error Import
import ManropeBold from '../../../theme/assets/fonts/Manrope-Bold.ttf';
// @ts-expect-error Import
import ManropeMedium from '../../../theme/assets/fonts/Manrope-Medium.ttf';
import { PdfPage } from './PdfPage';

interface Props {
  doc: InvoiceInterface;
  logo: string | undefined | null;
}

// Register Font
Font.register({
  family: 'Manrope',
  fonts: [
    {
      src: ManropeRegular,
    },
    {
      src: ManropeMedium,
    },
    {
      src: ManropeBold,
    },
  ],
});

export const InvoicePdf = ({ doc, logo }: Props) => {
  const getPages = useCallback(() => {
    const splittedProds = splitToPagesDynamic(doc?.products);

    return splittedProds.map((prods, index) => (
      <PdfPage
        key={index}
        company={doc?.company}
        doc={doc}
        products={prods}
        index={index}
        numberOfPages={splittedProds.length}
        logo={logo}
      />
    ));
  }, [doc, logo]);

  return <Document>{getPages()}</Document>;
};
