import * as React from 'react';
import { useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonInput, IonItem } from '@ionic/react';
import { TextFieldTypes } from '@ionic/core';
import { useNextSeries } from '../../../api/hooks/useNextSeries';
import { useParams } from 'react-router';
import { setToast } from '../../../store/core/coreSlice';
import { useAppDispatch } from '../../../store/hooks';
import { disableSubmitOnEnter } from '../../../config/utils/formUtils';
import { TEST_IDS } from '../../../config/utils/constants';

interface Props {
  seriesNameField: any;
  serialNumberField: any;
  formik: any;
  isClone?: boolean;
}

export const DocSeries = ({ seriesNameField, serialNumberField, formik, isClone }: Props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const isCreate = useMemo(() => !id, [id]);
  const { t } = useTranslation();
  const [docTypeChanged, setDocTypeChanged] = useState(false);
  const [seriesName, setSeriesName] = useState(seriesNameField.value || '0');
  const formikSeriesName = useMemo(() => formik.values?.[seriesNameField.name], [formik.values, seriesNameField.name]);
  const documentTypeId = useMemo(() => {
    return formik.values?.documentTypeId?.value || formik.values?.documentTypeId || '';
  }, [formik.values?.documentTypeId]);
  const deferredSeriesName = useDeferredValue(seriesName);
  const { data } = useNextSeries(deferredSeriesName, documentTypeId, id, isCreate || isClone, isClone);

  useEffect(() => {
    if (documentTypeId) setDocTypeChanged(true);
  }, [documentTypeId]);

  useEffect(() => {
    if (data?.serialNumber) {
      // console.log('data?.byDocType?.serialNumber: ', data?.byDocType?.serialNumber);
      // console.log('data?.byDocType?.seriesName: ', data?.byDocType?.seriesName);
      // console.log('formikSeriesName: ', formikSeriesName);
      if (data?.byDocType?.serialNumber && data?.byDocType?.seriesName && docTypeChanged) {
        setDocTypeChanged(false);
        // console.log('WILL SET BY DOC');
        setSeriesName(data?.byDocType.seriesName);
        formik.setFieldValue(seriesNameField.name, data?.byDocType.seriesName);
        formik.setFieldValue(serialNumberField.name, data?.byDocType.serialNumber);

        if (formikSeriesName !== '' && formikSeriesName !== data?.byDocType.seriesName) {
          dispatch(
            setToast({
              value: {
                show: true,
                msg: t('SALE.CHANGE_OF_SERIES_BY_DOC_MESSAGE'),
                isError: false,
                duration: 4000,
                position: 'bottom',
              },
            }),
          );
        }
        return;
      }

      formik.setFieldValue(serialNumberField.name, data?.serialNumber);

      if (data?.serialNumber === 1 && data?.seriesName !== '0') {
        dispatch(
          setToast({
            value: {
              show: true,
              header: t('SALE.FIRST_USAGE_OF_SERIES_NAME_HEADER'),
              msg: t('SALE.FIRST_USAGE_OF_SERIES_NAME_MESSAGE'),
              isError: false,
              duration: 6000,
              position: 'top',
            },
          }),
        );
      }
    }
  }, [data]);

  const onChange = (event: CustomEvent) => {
    // To check weird error
    console.log('event?.detail?.value: ', event?.detail?.value);
    if (event?.detail?.value?.includes('undefined')) return;
    setSeriesName(event?.detail?.value || '0');
    formik.handleChange(event);
  };

  // TODO: name of series should in English or Greek, or both? Is it different if both are applicable? Should we make a map to english
  return (
    <div className="flex-start-center-row-wrap">
      <div className="margin-r-20">
        <div className="padding-b-16 padding-l-12">{t('SALE.SERIES_NAME')}</div>
        <IonItem
          id={seriesNameField.name}
          className="width-140px-simple height-50px border-radius-input g-select-border cursorPointer"
          mode="md">
          <IonInput
            id={TEST_IDS.Invoice.invoiceSeriesName}
            className="height-48px"
            mode="md"
            type={seriesNameField.type as TextFieldTypes}
            name={seriesNameField.name}
            onKeyPress={disableSubmitOnEnter}
            placeholder={t(`SALE.${seriesNameField.placeholder}`) + '...'}
            onIonChange={onChange}
            value={formik.values?.[seriesNameField.name]}
          />
        </IonItem>
      </div>
      <div>
        <div className="padding-b-16 padding-l-12">{t('SALE.SERIAL_NUMBER')}</div>
        <IonItem
          id={serialNumberField.name}
          className="width-140px-simple height-50px border-radius-input g-select-border cursorPointer op-half flex-center-center-column-wrap"
          mode="md">
          <IonInput
            mode="md"
            className="height-48px"
            type={serialNumberField.type as TextFieldTypes}
            name={serialNumberField.name}
            onKeyPress={disableSubmitOnEnter}
            onIonChange={formik.handleChange}
            value={formik.values?.[serialNumberField.name]}
            disabled
          />
        </IonItem>
      </div>
    </div>
  );
};
