import { Error, GetEntities } from '../../interfaces/shared.interface';
import { apiRQ } from '../api';
import { useQuery } from 'react-query';
import { Country } from '../../interfaces/customer.interface';

export const getCountries = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<GetEntities<Country>> => {
  const resp = await apiRQ.get<GetEntities<Country>>('/countries', { params });

  return resp.data || [];
};

export const useCountries = (page: number, orderBy = 'id', order = 'ASC', search: string) => {
  return useQuery<GetEntities<Country>, Error>(
    ['useCountries', page, orderBy, order, search],
    () => getCountries({ page, orderBy, order, search: search }),
    {
      refetchOnWindowFocus: false,
    },
  );
};
