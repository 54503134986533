import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useCallback } from 'react';
import { MoneyCollection } from '../../../../interfaces/moneyCollection.interface';
// @ts-expect-error Import
import ManropeRegular from '../../../../theme/assets/fonts/Manrope-Regular.ttf';
// @ts-expect-error Import
import ManropeMedium from '../../../../theme/assets/fonts/Manrope-Medium.ttf';
// @ts-expect-error Import
import ManropeBold from '../../../../theme/assets/fonts/Manrope-Bold.ttf';
import { PdfCompanyLogo } from '../PdfCompanyLogo';
import { PdfCompanyTitle } from '../PdfCompanyTitle';
import { pdfStyles } from '../pdfStyles';
import { PdfMoneyCollectionBotSection } from './PdfMoneyCollectionBotSection';
import { PdfMoneyCollectionCheckDetails } from './PdfMoneyCollectionCheckDetails';
import { PdfMoneyCollectionTitle } from './PdfMoneyCollectionTitle';
import { PdfMoneyCollectionTradeInfo } from './PdfMoneyCollectionTradeInfo';
import { PdfMoneyCollectionTrader } from './PdfMoneyCollectionTrader';
import { PdfMoneyCollectionValueSection } from './PdfMoneyCollectionValueSection';

interface Props {
  doc: MoneyCollection;
  logo: string | undefined | null;
  currency: string | null | undefined;
}

// Register Font
Font.register({
  family: 'Manrope',
  fonts: [
    {
      src: ManropeRegular,
    },
    {
      src: ManropeMedium,
    },
    {
      src: ManropeBold,
    },
  ],
});

export const MoneyCollectionPdf = ({ doc, logo, currency }: Props) => {
  const getSection = useCallback(() => {
    return (
      <View style={styles.section}>
        <View>
          <View style={pdfStyles.flexStartSpaceRow}>
            <PdfCompanyTitle company={doc.company} />
            <PdfCompanyLogo company={doc.company} logo={logo} />
          </View>
          <View style={{ paddingTop: 4 }} />
          <PdfMoneyCollectionTitle doc={doc} />
          <PdfMoneyCollectionTrader doc={doc} />
          <PdfMoneyCollectionTradeInfo doc={doc} />
          <PdfMoneyCollectionValueSection doc={doc} currency={currency} />
          <PdfMoneyCollectionCheckDetails doc={doc} />
          <View style={{ marginTop: 10 }}>
            <PdfMoneyCollectionBotSection doc={doc} />
          </View>
        </View>
      </View>
    );
  }, [currency, doc, logo]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.index}>
          <Text style={pdfStyles.smallTitleRegular}>({doc?.serialNumber})</Text>
        </View>
        {getSection()}
        {getSection()}
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  index: {
    position: 'absolute',
    top: 2,
    left: 6,
  },
  page: {
    fontFamily: 'Manrope',
    fontSize: 12,
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    padding: 10,
    marginBottom: 20,
    flexGrow: 1,
    justifyContent: 'space-between',
  },
});
