import { ProductFormData } from '../../../interfaces/product.interface';
import { apiRQ } from '../../api';
import { useQuery } from 'react-query';

const getProductForm = async (): Promise<ProductFormData> => {
  const resp = await apiRQ.get<ProductFormData>('/form-data/product');

  return resp.data;
};

export const useProductForm = () => {
  return useQuery<ProductFormData, Error>(['getProductForm'], () => getProductForm(), { refetchOnWindowFocus: false });
};
