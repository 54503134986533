import i18next from 'i18next';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { usePlans } from '../../api/hooks/usePlans';
import { SUBSCRIPTION } from '../../interfaces/enumerables';
import { PATHS } from '../../navigation/data';
import { selectSelectedCompany } from '../../store/companies/companiesSlice';
import { setToast } from '../../store/core/coreSlice';
import { store } from '../../store/store';

export const useHasActivePlan = (redirectHome = false, planId?: SUBSCRIPTION) => {
  const company = useSelector(selectSelectedCompany);
  const { data, isLoading, isSuccess, isError } = usePlans(company?.vatNumber || '');
  const history = useHistory();

  const redirectHomeAction = useCallback((msg: string) => {
    history.replace(PATHS.HOME);
    store.dispatch(
      setToast({
        value: {
          show: true,
          msg: i18next.t(msg),
          isError: true,
          duration: 30000,
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (isSuccess && redirectHome) {
      if (!data?.hasPlansActive) {
        redirectHomeAction('PLANS.NO_ACTIVE_PLANS_DISABLED_FEATURES');
        return;
      }

      if (planId && !data?.activePlans?.[planId]) {
        redirectHomeAction('PLANS.NO_ACTIVE_PLAN_FOR_FEATURE');
        return;
      }
    }
  }, [isSuccess, redirectHome, data, planId, redirectHomeAction]);

  // Something is off with the server => let user use the app
  if (isError) return true;

  return isLoading || data?.hasPlansActive;
};
