import { apiRQ } from '../api';
import { useQuery } from 'react-query';
import { Doy } from '../../interfaces/customer.interface';
import { Error, GetEntities } from '../../interfaces/shared.interface';

export const getDoys = async (params: {
  page: number;
  orderBy: string;
  order: string;
  search?: string;
}): Promise<GetEntities<Doy>> => {
  const resp = await apiRQ.get<GetEntities<Doy>>('/doys', { params });

  return resp.data || [];
};

export const useDoy = (page: number, orderBy = 'id', order = 'ASC', search: string) => {
  return useQuery<GetEntities<Doy>, Error>(
    ['getDoys', page, orderBy, order, search],
    () => getDoys({ page, orderBy, order, search: search }),
    {
      refetchOnWindowFocus: false,
    },
  );
};
