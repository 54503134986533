import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from '../../../../interfaces/company.interface';
import { selectSelectedCompany, setSelectedCompanyAsync } from '../../../../store/companies/companiesSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface Props {
  company: Company;
}

const CompanyCardComponent = ({ company }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedCompany = useAppSelector(selectSelectedCompany);

  const isCompanySelected = useMemo(() => company?.id === selectedCompany?.id, [company?.id, selectedCompany?.id]);

  const selectCompany = useCallback(() => {
    dispatch(setSelectedCompanyAsync(company));
  }, [company, dispatch]);

  const getSelectedCompanyClass = useCallback(() => {
    if (isCompanySelected) return 'selectedCompany';

    return '';
  }, [isCompanySelected]);

  const getCompanyName = useCallback(() => {
    if ((company?.branchSerial || 0) > 0) return `${company?.title} (${company?.branchSerial})`;

    return company.title;
  }, [company?.branchSerial, company.title]);

  return (
    <div
      className={`flex-center-center-column-nowrap companyCard ${getSelectedCompanyClass()}`}
      onClick={selectCompany}>
      <div className="boldText5 margin-b-12">{getCompanyName()}</div>
      <div className="mediumText4 margin-b-4">
        {t('SHARED.VAT_NUMBER')}: {company.vatNumber}
      </div>
      <div className="mediumText4">
        {company.owner?.firstName} {company.owner?.lastName}
      </div>
    </div>
  );
};

export default memo(CompanyCardComponent);
