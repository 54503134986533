import { IonContent, IonImg } from '@ionic/react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { usePublicDoc } from '../../api/hooks/sales/usePublicDoc';
import { useCompanyLogo } from '../../api/hooks/useCompanyLogo';
import { Loading } from '../../components/atoms/Loading';
import { DocView } from '../../components/molecules/docView/DocView';
import { InvoicePdf } from '../../components/molecules/pdf/InvoicePdf';
import { pdfStyles } from '../../components/molecules/pdf/pdfStyles';
import logo from '../../theme/assets/logo.png';

export const PublicDocScreen = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { data, isFetched, isError, isLoading } = usePublicDoc(id);
  const { data: logoData, isFetched: isFetchedLogo } = useCompanyLogo(data?.data?.company?.logoUrl || '');

  const getLogo = useCallback(() => {
    return (
      <div className="flex-center-center-column-nowrap">
        <IonImg src={logo} style={styles.logo} />
      </div>
    );
  }, []);

  const getError = useCallback(() => {
    if ((!data?.success && data?.errorCode) || isError) {
      return (
        <div className="normalText6 max-width-460px text-center padding-t-100">
          {t('API_ERRORS.UNABLE_TO_GET_PUBLIC_DOC')}
        </div>
      );
    }
  }, [data, isError]);

  const downloadDoc = useCallback(() => {
    if (isFetched && data?.success && data?.data?.id && data?.data?.company && isFetchedLogo) {
      const doc = data?.data;
      const fileName = `billease-${doc?.customer?.companyName}-${doc?.documentDate}.pdf`;

      return (
        <div className="padding-b-10">
          <PDFDownloadLink
            document={<InvoicePdf doc={data?.data} logo={logoData} />}
            fileName={fileName}
            style={pdfStyles.ionButtonOutline}>
            {t('FORMS.DOWNLOAD_PDF')}
          </PDFDownloadLink>
        </div>
      );
    }
  }, [isFetched, data?.success, data?.data, isFetchedLogo, logoData, t]);

  const getDocView = useCallback(() => {
    if (data?.success && data?.data && isFetched) {
      return <DocView doc={data?.data} logo={logoData} />;
    }
  }, [data?.success, data?.data, logoData, isFetched]);

  const getLoading = useCallback(() => {
    if (isLoading) {
      return <Loading backgroundFull />;
    }

    return null;
  }, [isLoading]);

  return (
    <IonContent scrollX={true} scrollY={true}>
      {getLoading()}
      <div className="minWidth860 flex-center-center-column-nowrap">
        {getLogo()}
        {downloadDoc()}
        {getError()}
        {getDocView()}
      </div>
    </IonContent>
  );
};

const styles = {
  logo: {
    width: 200,
    height: 100,
  },
};
