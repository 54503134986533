import { useCallback } from 'react';
import { InvoiceInterface } from '../../../interfaces/invoice.interface';
import { splitToPagesDynamic } from '../../../services/utils/pdf';
import { EmailPill } from '../../atoms/EmailPill';
import { DocViewPage } from './DocViewPage';
import './docViewStyles.scss';

interface Props {
  doc: InvoiceInterface;
  logo: string | null | undefined;
}

export const DocView = ({ doc, logo }: Props) => {
  const getPages = useCallback(() => {
    const splittedProds = splitToPagesDynamic(doc?.products);

    return splittedProds.map((prods, index) => (
      <DocViewPage
        key={index}
        company={doc?.company}
        doc={doc}
        products={prods}
        index={index}
        numberOfPages={splittedProds.length}
        logo={logo}
      />
    ));
  }, [doc, logo]);

  const getEmailPill = useCallback(() => {
    if (doc?.emailSentToCustomerAt) {
      return <EmailPill size={16} top={-10} date={doc?.emailSentToCustomerAt} />;
    }
  }, [doc?.emailSentToCustomerAt]);

  return (
    <div className="margin-b-80">
      {getEmailPill()}
      {getPages()}
    </div>
  );
};
